
import { IonContent, IonHeader, IonPage, IonToolbar, IonLabel, IonInput, IonTextarea, IonItem, IonList } from '@ionic/vue';
import { defineComponent, reactive, computed } from 'vue';
import useValidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';

export default defineComponent({
  name: 'Contact',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
    IonTextarea,
    IonItem,
    IonList 
  },
  setup() {
    const state = reactive({
      name: '',
      email: '',
      message: '',
    })
    const rules = computed(() => {
      return {
        name: { required, minLength: minLength(2) },
        email: { required, email },
      }
    })

    const v$ = useValidate(rules, state)

    // const result = ref(null)

    // axios.get('../views/Contact.vue')
    //   .then(data => result.value = data);

    return {
      state,
      v$,
      // result
    }
  },
  methods: {
    submitForm() {
      console.log('Form Values', this.state)

      this.v$.$validate()
      if (!this.v$.$error) {
        alert('Form sucessfully submitted. I will get back to you shortly.')
      }
      else {
        alert('Error! Please fill in all the required fields and click submit again.')
      }    
    }
  }
  
});
