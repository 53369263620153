
import { IonContent, IonHeader, IonPage, IonToolbar, IonIcon, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { logoGithub, logoLinkedin } from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonButton
  },
  setup () {
    return {
      logoGithub,
      logoLinkedin
    }
  }
});
