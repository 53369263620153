
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Resume',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar
  }
});
